<template>
  <div class="footer">
    <div class="footer-about">
      <div class="footer-content">
        <h2>关于我们：</h2>
        <p>
          一群为儿童设计的人，童心未泯却足够专业。<br />我们充满各种奇思妙想和暖暖的爱意，努力创造孩子真正喜欢的产品。
        </p>
      </div>
    </div>
    <div class="footer-copyright">
      <div v-if="tiepiren">
        Copyright © 2016 Tinman Arts Inc. All rights reserved. <br />成都书声科技有限公司
        <a style="color:#999" href="//beian.miit.gov.cn/" target="_blank">蜀ICP备19003148号-36</a>
      </div>
      <div v-if="shushanyoulu">
        Copyright © 2024 成都书山有路科技有限公司
        <a style="color:#999" href="//beian.miit.gov.cn/" target="_blank">蜀ICP备2024084283号</a>
      </div>
      <div v-if="ssyl">
        Copyright © 2024 成都书山有路科技有限公司
        <a style="color:#999" href="//beian.miit.gov.cn/" target="_blank">蜀ICP备2024084283号-2</a>
      </div>
      <div v-if="liankousuan">
        Copyright © 2024 南京铁皮人信息科技有限公司
        <a style="color:#999" href="//beian.miit.gov.cn/" target="_blank">苏公网安备32011102010544号</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterComponent',
  computed: {
    // 获取当前域名
    currentHost() {
      return location.host
    },
    // 判断当前域名是否为 书声科技
    tiepiren() {
      return this.currentHost === 'tinman.cn' || this.currentHost === 'www.tinman.cn'
    },
    // 判断当前域名是否为 书山有路
    shushanyoulu() {
      return this.currentHost === 'cdssylkj.com' || this.currentHost === 'www.cdssylkj.com'
    },
    // 判断当前域名是否为 南京铁皮人叫叫练口算
    liankousuan() {
      return this.currentHost === 'tinmannj.com' || this.currentHost === 'www.tinmannj.com'
    },
    // 判断当前域名是否为 书山有路 短链
    ssyl() {
      return this.currentHost === '0xxa.cn' || this.currentHost === 'www.0xxa.cn'
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-about {
  background-color: #fafafa;
  color: #999;
  padding-bottom: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-content {
    width: 50%;
    margin: 50px 0;
    box-sizing: border-box;
    p {
      font-size: 20px;
      line-height: 2.2;
      padding-top: 20px;
    }
  }
}
.footer-copyright {
  line-height: 1.4;
  height: 60px;
  text-align: center;
  color: #999;
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
.footer-copyright:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
</style>
